import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  modal: {
    minWidth: '30vw'
  },
  modalTitle: {
    backgroundColor: "#424853",
    color:"#FFF"
  },
  list: {
    backgroundColor: "#1e1f25",
  },
  listItem: {
    color:"#dbfdc2",
    '&:hover': {
      background: "#202625",
    }
  },
  listItemClose: {
    color:"#FFF",
    '&:hover': {
      background: "#202625",
    }
  }
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, friends } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog className={classes.modal} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle className={classes.modalTitle} id="simple-dialog-title">Friends</DialogTitle>
      <List className={classes.list}>
        {friends.map((friend) => (
          <ListItem button className={classes.listItem} onClick={() => handleListItemClick(friend)} key={friend.steamid}>
            <ListItemAvatar>
              <Avatar  variant="square" src={friend.avatar} className={classes.avatar} />
            </ListItemAvatar>
            <ListItemText primary={friend.personaname} />
          </ListItem>
        ))}

        <ListItem className={classes.listItemClose} autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <Avatar>
              <CancelOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Close" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default SimpleDialog;
