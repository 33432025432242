import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserCard from './UserCard.js';
import SharedGames from './SharedGames.js';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink }  from 'react-router-dom';

const styles  = theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  linkButton: {
    textDecoration: "none"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  }
  });

class Album extends React.Component {

  searchAgain() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Container maxWidth="md">
          <RouterLink to="/" className={classes.linkButton}>
            <Button variant="contained" color="primary" className={classes.heroButtons}>
              Search Again
            </Button>
          </RouterLink>
        </Container>
      </React.Fragment>
    );
  }

    render() {

      const { classes } = this.props;

      // Retrieve the steamIds from the state pushed into history
      const users = this.props.location.state.steamIds

      return (
        <React.Fragment>
          <CssBaseline />
          {/*<AppBar position="relative">
            <Toolbar>
              <CameraIcon className={classes.icon} />
              <Typography variant="h6" color="inherit" noWrap>
              </Typography>
            </Toolbar>
          </AppBar>*/}
          <main>
          {this.searchAgain()}
            {/* Hero unit */}
            {/*<div className={classes.heroContent}>
              <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                  Album layout
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                  Something short and leading about the collection below—its contents, the creator, etc.
                  Make it short and sweet, but not too short so folks don&apos;t simply skip over it
                  entirely.
                </Typography>
                <div className={classes.heroButtons}>
                  <Grid container spacing={2} justify="center">
                    <Grid item>
                      <Button variant="contained" color="primary">
                        Main call to action
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary">
                        Secondary action
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>*/}
            <Container className={classes.cardGrid} maxWidth="md">
              <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                Profiles
              </Typography>
                {/* Start user cards */}
                <UserCard steamId={users} />
                {/* End user cards */}

            </Container>

            <Container className={classes.cardGrid} maxWidth="md">
              <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                Shared Games
              </Typography>
              <SharedGames steamIds={users} />

            </Container>


          </main>

        </React.Fragment>
      );

  }
}

export default withRouter(withStyles(styles)(Album));
