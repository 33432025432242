import React, { useState, Fragment } from "react";
import clsx from "clsx";
import { Router, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";


import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Home from './SteamIdForm.js';
import Grid from './VanityUrl.js';
import Album from './Album.js';

/**
 * AppBar controls the top menu and drawer slider, also
 * houses Router logic
 */

const drawerWidth = 240;
const history = createBrowserHistory();


const pages = {
  home: {
    path: "/",
    title: "Find Shared Games"
  },
  getSteamId: {
    path: "/get-steam-id",
    title: "Get Steam Id"
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    backgroundColor: "#1b2838",
    color: "#FFF"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  toolbarMargin: theme.mixins.toolbar,
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#1b2838"
  }
});

const MyToolbar = withStyles(styles)(({ classes, title, onMenuClick }) => (
  <Fragment>
    <AppBar className={classes.aboveDrawer}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
    <div className={classes.toolbarMargin} />
  </Fragment>
));

const MyDrawer = withStyles(styles)(
  ({ classes, variant, open, onClose, onItemClick }) => (
    <Router history={history}>

      <Route path="/results">
        <Album />
      </Route>

      <Drawer
        variant={variant}
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          className={clsx({
            [classes.toolbarMargin]: variant === "persistent"
          })}
        />


        <List>
          <ListItem
            button
            component={Link}
            to={pages.home.path}
            onClick={onItemClick(pages.home.title)}
          >
            <ListItemText>Find Shared Games</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to={pages.getSteamId.path}
            onClick={onItemClick(pages.getSteamId.title)}
          >
            <ListItemText>Get Steam Id</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Route exact path={pages.home.path} component={Home} />
        <Route path={pages.getSteamId.path} component={Grid} />
      </main>
    </Router>
  )
);

function AppBarInteraction({ classes, variant }) {
  const [drawer, setDrawer] = useState(false);

  // Try to dynamically set title
  let pageTitle;
  let pathFound = false;
  for (const page in pages) {
    if (page.path === window.location.pathname) {
      pageTitle = page.title;
      pathFound = true;
      break;
    }
  }

  if (!pathFound) {
    pageTitle = pages.home.title;
  }

  const [title, setTitle] = useState(pageTitle);

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const onItemClick = title => () => {
    setTitle(title);
    setDrawer(variant === "temporary" ? false : drawer);
    setDrawer(!drawer);
  };

  return (
    <div className={classes.root}>
      <MyToolbar title={title} onMenuClick={toggleDrawer} />
      <MyDrawer
        open={drawer}
        onClose={toggleDrawer}
        onItemClick={onItemClick}
        variant={variant}
      />
    </div>
  );
}

export default withStyles(styles)(AppBarInteraction);
