import './App.css';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ReactGA from 'react-ga'; // analytics
import { withStyles } from '@material-ui/core/styles';
import Config from './config.js';

const prod = Config.config.env === 'prod';

if (prod) {
  ReactGA.initialize('UA-82048441-2');
}

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),

  },
});

class Footer extends React.Component {

  render() {
    const { classes } = this.props;

    // Send analytics
    if (prod) {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    return (

      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Steam Tools
          </Typography>
        {/*<Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            Something here to give the footer a purpose!
          </Typography>*/}
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <a color="inherit" href="https://jamespooley.codes/">
            jamespooley.codes
            </a>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
