import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Alert from '@material-ui/lab/Alert';
import Config from './config.js';
import Link from '@material-ui/core/Link';
import { green } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga'; // analytics
import SteamFriendsDialog from './SteamFriendsDialog.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import {seo} from './helper/seo';


/** Min ids required to search **/
const MIN_IDS = 1;

/** Max ids required to search **/
const MAX_IDS = 5;

const STEAM_ID_LENGTH = 17;

const ALERT_SEVERITY = Object.freeze({
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success"
});

// Initial state for resetting
const initialState = {
  ids: [""],
  usersSteamId: '',
  loadedUser: null,
  suggestions: [],
  loadedFriends: [],
  errorMessage: null,
  errorMessages: [],
  modalOpen: false,
  loadingFriends: false,
  userSteamIdErrorMessage: ''
}

const styles = theme => ({
  inputs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex"
  },
  heroButtons: {
    margin: theme.spacing(2),
  },
  searchTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  alert: {
    margin: theme.spacing(2),
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "center"
  },
  wrapper: {
    position: 'relative',
  },
  nameSpan: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  resetButton: {
    margin: theme.spacing(2),
    backgroundColor: "#37474f",
    '&:hover': {
      background: "#102027",
    }
  }
});

/**
 *  This component displays the form for
 *  searching for shared games by using 
 *  steam Ids.
 */
class SteamIdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addSteamIdField = this.addSteamIdField.bind(this);
  }

  componentDidMount() {
    seo({
      title: 'Find shared games between friends on Steam',
      metaDescription: 'With Steam Tools you can search and compare which games both you and your friends own. Steam Tools will automatically pull your friends list for easy comparison.'
    });
  }

  // Updates current state when text field changes
  handleChange(i, event) {
    // get current array, update and replace it in the state
    let updatedIds = [...this.state.ids];
    updatedIds[i] = event.target.value;

    // If a valid id was entered, try to remove it from possible suggestions
    if (event.target.value) {
      if (this.isSteamIdValid(event.target.value, false)) {
        this.removeFromSuggestions(event.target.value);
      }
    }


    this.setState({ ids: updatedIds });
  }

  /**
   * Updates current state when 'Your Steam Id' 
   * text field changes
   * 
   * @param {Event} event 
   */
  handleUserIdChange(event) {
    //reset error message
    this.setState({
      userSteamIdErrorMessage: '',
      errorMessages: []
    })

    let value = event.target.value;

    if (this.isSteamIdValid(value)) {
      this.fetchUser(value);
    }

    this.setState({ usersSteamId: event.target.value });
  }

  resetState() {
    this.setState(initialState);
  }

  /**
   * Add a new steam id field to the form
   */
  addSteamIdField() {
    let updatedIds = [...this.state.ids];
    // add new empty field
    if (updatedIds.length < MAX_IDS) {
      updatedIds.push("");
      this.setState({ ids: updatedIds });
    }
    // Scroll the add button into view so it does not go below screen
    this.scrollToBottom();
  }

  /**
   * Scroll el element into view specified in DOM
   */
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Adds errorMessage to the DOM
   * 
   * @param {String} errorMessage 
   */
  addErrorMessage(errorMessage) {
    this.setState(state => {
      const errorMessages = state.errorMessages.concat(errorMessage);

      return {
        errorMessages
      };
    });

  }

  /**
   * Fetches all the friends Steam Ids of the given users Steam Id.
   * Steams API's do not give the username on their GetFriends API,
   * Once retrieved, pushes all IDS to an ID array and calls
   * fetchFriendsNames()
   * 
   * @param {String} steamId 
   */
  fetchFriendsIds(steamId) {

    if (this.state.loadedFriends && this.state.loadedFriends.length === 0) {
      this.setState({ loadingFriends: true });
      //this.sleep(2000).then(() => {
      fetch(Config.config.backend.usersUrl + '/friends/' + steamId)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.friendslist) {
              let friendsResponse = result.friendslist.friends;

              // Need to collect all ids of the friends
              let friendsIds = [];
              for (let i = 0; i < friendsResponse.length; i++) {
                if (i === 100) {
                  // Steams GetPlayerSummaries API has a max limit of 100 ids
                  break;
                }
                friendsIds.push(friendsResponse[i].steamid);
              }

              //console.log(friendsIds);
              this.fetchFriendsNames(friendsIds);
            }

          },
          (error) => {
            console.log(error);
            this.setState({ loadingFriends: false });
            this.addErrorMessage(this.buildMessage("Error retrieving Friends list.", ALERT_SEVERITY.ERROR))
          }
        );
      //})
    }


  }

  buildMessage(message, severity) {
    let newMessage = {
      message: message,
      severity: severity
    }
    return newMessage;
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  /**
   * Fetchs the player summaries of the given steam ids,
   * then loads them into loadedFriends and suggestions state
   * 
   * @param {Array} friendsIds 
   */
  fetchFriendsNames(friendsIds) {
    // sleep to test async
    //this.sleep(500).then(() => {
    fetch(Config.config.backend.usersUrl + '/' + friendsIds)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loadedFriends: result.response.players,
            suggestions: result.response.players,
            loadingFriends: false
          });
        },
        (error) => {
          console.log(error);
          this.setState({ loadingFriends: false });
          this.addErrorMessage(this.buildMessage("Error retrieving Friends names.", ALERT_SEVERITY.ERROR))
          
        }
      );
    //})
  }

  /**
   * Fetch the player summary of the users steam id,
   * then load it into loadedUser state
   * 
   * @param {String} steamId 
   */
  fetchUser(steamId) {
    //this.sleep(500).then(() => {
    fetch(Config.config.backend.usersUrl + '/' + steamId)
      .then(res => res.json())
      .then(
        (result) => {

          if (result.response.players[0].steamid) {
            this.setState({
              loadedUser: result.response.players[0],
            });
            return result.response.players[0];
          } else {
            this.addErrorMessage(this.buildMessage("Steam profile could not be found for ID " + steamId, ALERT_SEVERITY.WARNING));
            return null;
          }


        },
        (error) => {
          //console.log(error);
          this.addErrorMessage(this.buildMessage("Unable to auto retrieve User profile.", ALERT_SEVERITY.WARNING));
          return null;
        }
      ).then((loadedUser) => {
        // User was loaded, if they exist, pull their friends list next
        if (loadedUser !== null && loadedUser.communityvisibilitystate === 3) {
          this.fetchFriendsIds(loadedUser.steamid);
        } if (loadedUser !== null && loadedUser.communityvisibilitystate === 1) {
          // private profile
          this.addErrorMessage(this.buildMessage("Cannot access friends list due to private profile, IDs can only be entered manually", ALERT_SEVERITY.INFO));
        } 

      }

      );
    //})
  }

  // eslint-disable-next-line
  removeSteamIDField(i, event) {
    // get current array, update and replace it in the state
    let updatedIds = [...this.state.ids];


    // Get the suggestions and loaded friends
    let loadedFriends = [...this.state.loadedFriends];
    let updatedSuggestions = [...this.state.suggestions];

    // remove the id field from array
    if (i >= MIN_IDS && loadedFriends && updatedSuggestions) {
      // Add the result back into suggestions IF 
      // it was a loaded in friend
      for (let j = 0; j < loadedFriends.length; j++) {

        if (loadedFriends[j].steamid === updatedIds[i]) {
          updatedSuggestions.push(loadedFriends[j]);
          break;
        }

      }

      updatedIds.splice(i, 1);

    }

    // let updatedIds = [null, null];
    this.setState({
      ids: updatedIds,
      suggestions: updatedSuggestions
    });
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * Called on close of Friends suggestion modal. Updates
   * the UI with the given selection.
   * 
   * @param {String} value 
   */
  handleClose = (value) => {
    if (value) {
      let updatedIds = [...this.state.ids];

      // If the first Id field is empty, set the value to that one
      if (updatedIds[0] === '') {
        updatedIds[0] = value.steamid;
      } else {
        updatedIds[updatedIds.length] = value.steamid;
      }

      this.removeFromSuggestions(value.steamid);

      this.setState({
        ids: updatedIds,
        modalOpen: false
      });
    } else {
      this.setState({
        modalOpen: false
      })
    }

  };


  /**
   * Removes the steamId from appearing in Friends Suggestion modal
   * 
   * @param {String} steamId 
   */
  removeFromSuggestions(steamId) {
    let updatedSuggestions = [...this.state.suggestions];

    let indexToRemove = null;
    if (updatedSuggestions) {
      for (let i = 0; i < updatedSuggestions.length; i++) {
  
        if (updatedSuggestions[i].steamid === steamId) {
          indexToRemove = i;
          break;
        }
      }
    }

    if (indexToRemove !== null && updatedSuggestions) {
      updatedSuggestions.splice(indexToRemove, 1);
    }

    this.setState({
      suggestions: updatedSuggestions
    });
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true
    })
  };

  /**
   * Validates a Steam ID
   * 
   * @param {*} steamId 
   * @param {*} setState 
   * @returns 
   */
  isSteamIdValid(steamId, setState = true) {
    if (steamId === null || steamId === undefined) {
      return false;
    }

    let trimmedId = steamId.trim();

    if (trimmedId.length !== STEAM_ID_LENGTH) {

      if (setState) {
        this.setState({
          userSteamIdErrorMessage: "Steam ID should be 17 digits long"
        });
      }

      return false;
    } else if (!this.isNumeric(trimmedId)) {
      if (setState) {
        this.setState({
          userSteamIdErrorMessage: "Steam ID should be numeric"
        });
      }

      return false;
    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (Config.config.env === 'prod') {
      ReactGA.event({
        category: 'Interaction',
        action: 'Submitted shared games search'
      });
    }

    const { history } = this.props;

    // Reset errorMessages
    this.setState({ errorMessages: [] });

    let steamIds = this.state.ids;

    // Add usersSteamId
    steamIds.push(this.state.usersSteamId);

    // Validate Ids
    let errors = false;
    for (let i = 0; i < steamIds.length; i++) {
      let trimmedId = steamIds[i].trim();

      if (trimmedId.length !== STEAM_ID_LENGTH) {
        let message = this.buildMessage("The Steam ID length should be " + STEAM_ID_LENGTH + " digits: " + trimmedId, ALERT_SEVERITY.ERROR);
        this.addErrorMessage(message);
        errors = true;
      } else if (!this.isNumeric(trimmedId)) {
        // verify is isNumeric
        let message = this.buildMessage("Given ID is not numeric: " + trimmedId, ALERT_SEVERITY.ERROR);
        this.addErrorMessage(message);
        errors = true;
      }
    }

    if (errors) {
      return;
    }

    // send to results page and set steamIds into the state
    if (history) history.push({ pathname: '/results', state: { steamIds: this.state.ids } });
  }

  /**
   * Returns the player summary for the given steamId if its
   * in loadedFriends
   * 
   * @param {String} steamId 
   * @returns player summary from loadedFriends
   */
  getPlayerSummary(steamId) {
    let loadedFriends = [...this.state.loadedFriends];

    if (loadedFriends) {
      for (let i = 0; i < loadedFriends.length; i++) {
        if (loadedFriends[i].steamid === steamId) {
          return loadedFriends[i];
        }
      }
    }

    return null;
  }

  Form() {
    const { classes } = this.props;

    const { ids, suggestions, modalOpen, usersSteamId, loadingFriends, userSteamIdErrorMessage, loadedUser } = this.state;
    const errorMessages = this.state.errorMessages;

    let disableFields = true;
    if (usersSteamId) {
      disableFields = usersSteamId.length >= 17 ? false : true;
    }

    let disableAddBySteamFriends = true;
    if (suggestions) {
      disableAddBySteamFriends = suggestions.length > 0 ? false : true;
    }

    let steamIdError = userSteamIdErrorMessage !== '';

    let disableAddButton = ids.length === MAX_IDS;

    // Setup names of loaded users
    let loadedNames = [];
    for (let i = 0; i < ids.length; i++) {
      let summary = this.getPlayerSummary(ids[i]);
      loadedNames.push(summary);
    }

    return (
      <React.Fragment>
        <Container maxWidth="sm">
          <Typography component="h5" variant="h5" align="center" color="textPrimary" className={classes.searchTitle}>
            Enter Steam IDs to find shared games
            </Typography>
          <Typography component="p" variant="body1" align="left" color="textPrimary" className={classes.searchTitle}>
            Enter you and your friends Steam IDs below to see which games you have in common. Not sure how to
              find the Steam ID? Check out this page on <Link href="/get-steam-id">How to get Steam IDs</Link>.
            </Typography>

          {errorMessages.map((message, i) => (
            <Alert className={classes.alert} key={i} severity={message.severity}>{message.message}</Alert>
          ))}

          <form onSubmit={this.handleSubmit} >

            <div className={classes.wrapper}>
              <TextField
                error={steamIdError}
                disabled={!disableAddBySteamFriends}
                helperText={userSteamIdErrorMessage}
                id="standard-basic"
                label={"Your Steam ID"}
                name={"users-steam-id"}
                value={usersSteamId}
                required
                onChange={this.handleUserIdChange.bind(this)}
                className={classes.inputs} />

              {loadedUser !== null &&
                <span className={classes.nameSpan}>{loadedUser.personaname}</span>
              }

            </div>

            { /* prints out fields dynamically based on ids in this.state.ids */}
            {ids.map((id, i) => (

              <div key={id} className={classes.wrapper}>

                <TextField
                  id="standard-basic"
                  label={"Your Friends Steam ID"}
                  name={"id-" + i}
                  value={id}
                  disabled={disableFields}
                  required
                  onChange={this.handleChange.bind(this, i)}
                  className={classes.inputs}
                  InputProps={
                    i >= MIN_IDS ? {
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label="remove field"
                            onClick={this.removeSteamIDField.bind(this, i)}
                            edge="end">
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>)
                    } : undefined} />

                {loadedNames[i] !== null &&
                  <span className={classes.nameSpan}>{loadedNames[i].personaname}</span>
                }

              </div>

            ))}

            <Typography component="p" variant="body1" align="center" color="textPrimary" className={classes.searchTitle}>
              Comparing {ids.length + 1}/{MAX_IDS + 1} steam accounts
              </Typography>

            <div className={classes.buttonWrapper}>

              <span className={classes.wrapper}>
                <Button disabled={disableAddBySteamFriends} variant="outlined" color="primary" onClick={this.handleClickOpen.bind(this)}>
                  Add by Steam Friends
              </Button>
                {loadingFriends && <CircularProgress size={24} className={classes.buttonProgress} />}
              </span>

              {suggestions.length > 0 &&
                <SteamFriendsDialog friends={suggestions} open={modalOpen} onClose={this.handleClose.bind(this)} />
              }

              <Button type="button" value="addSteamId" disabled={disableAddButton ? true : false} variant="contained" color="primary" onClick={this.addSteamIdField} className={classes.heroButtons}>
                Add another Steam Id
                </Button>


              <Button type="submit" value="Submit" variant="contained" color="secondary" className={classes.heroButtons}>
                Get Shared Games
                </Button>

            </div>

            <div className={classes.buttonWrapper}>
              <Button type="button" value="reset" onClick={this.resetState.bind(this)} variant="contained" color="secondary" className={classes.resetButton}>
                Reset form
                </Button>
            </div>

            {/* For srolling to bottom when neeed */}
            <div ref={el => { this.el = el; }} />

          </form>
        </Container>

      </React.Fragment>
    );

  }

  render() {
    return this.Form();
  }

}

export default withRouter(withStyles(styles)(SteamIdForm));
