const dev = {
  env: "dev",
  backend: {
    gamesOwnedUrl: "http://localhost:3001/games",
    usersUrl: "http://localhost:3001/users"
  }
};

const prod = {
  env: "prod",
  backend: {
    gamesOwnedUrl: "https://steam-api.jamespooley.codes/games",
    usersUrl: "https://steam-api.jamespooley.codes/users"
  }
};

// react-script build sets NODE_ENV to production automatically
const config = process.env.NODE_ENV === 'production' ? prod : dev;

// eslint-disable-next-line
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  config
};
