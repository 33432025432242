import React from 'react';
//import ReactDOM from 'react-dom';
import Config from './config.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles  = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  onlineStatus: {
    backgroundColor: "#76ff03"
  },
  otherStatus: {
    backgroundColor: "#e0e0e0"
  },
  cardGrid: {
    justifyContent: 'center'
  }

});

class UserCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      loadedUsers: []
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  componentDidUpdate(prevProps) {
    // Update component if steamId changed
    if (this.props.steamId !== prevProps.steamId) {
      this.fetchUser();
    }
  }

  fetchUser() {
    fetch(Config.config.backend.usersUrl + '/' + this.props.steamId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            loadedUsers: result.response.players
          });
                          console.log("usercard mounted", result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { classes } = this.props;
    const { error, isLoaded, loadedUsers } = this.state;

    // Convert status to a string
    loadedUsers.forEach((user, i) => {
      switch(user.personastate) {
        case 0:
          if (user.communityvisibilitystate === 1) {
              user.status = "Private";
          } else {
              user.status = "Offline";
          }
          break;
        case 1:
          user.status = "Online";
          break;
        case 2:
          user.status = "Busy";
          break;
        case 3:
          user.status = "Away";
          break;
        case 4:
          user.status = "Snooze";
          break;
        case 5:
          user.status = "Looking to trade";
          break;
        case 6:
          user.status = "Looking to play";
          break;
        default:
          user.status = "Unknown";
      }

    });


    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Grid container className={classes.cardGrid} spacing={4}>
          {loadedUsers.map((user) => (
            <Grid item key={user.steamid} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={user.avatarfull}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {user.personaname}
                  </Typography>

                  {/* <OwnedGames steamId={user.steamid} />*/}

                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button size="small" color="primary" href={user.profileurl}>
                    View
                  </Button>

                    <Chip className={user.personastate === 1 ? classes.onlineStatus : classes.otherStatus} label={user.status} />

                  {/*<Button size="small" color="primary">
                    Edit
                  </Button>*/}
                </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

      );
    }
  }
}

export default withStyles(styles)(UserCard);
