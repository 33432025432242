import React from 'react';
//import ReactDOM from 'react-dom';
import Config from './config.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
});

// Gets Shared games between multiple users
class SharedGames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      responses: []
    };
  }

  componentDidMount() {
    let steamIds = this.props.steamIds;
    //console.log("Steam Ids to proc " + steamIds);

    var that = this;
    // Define a promise to acces gamesOwnedUrl for each steamId and convert to json
    var promises = steamIds.map(id => fetch(Config.config.backend.gamesOwnedUrl + '/' + id));

    // Use defined promise to execute all URL calls at once
    Promise.all(promises).then(function (responses) {
      // wait and return all promises once all calls complete , may not be needed
      return Promise.all(responses.map(function (response) {
        return response.json();
      }));
    }).then(function (data) {
      // Log the data to the console
      // You would do something with both sets of data here
      that.setState({
        isLoaded: true,
        responses: data
      });

    }).catch(function (error) {
      // if there's an error, log it
      console.log(error);

      that.setState({
        isLoaded: true,
        error: error
      });
    });
  }

  getCommonElementsFromArrays(arrays) {
    var result = arrays.shift().reduce(function (res, v) {
      if (res.indexOf(v) === -1 && arrays.every(function (a) {
        return a.indexOf(v) !== -1;
      })) res.push(v);
      return res;
    }, []);

    return result;
  }

  render() {
    const { error, isLoaded, responses } = this.state;
    const { classes } = this.props;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let gameArrays = [];

      // load arrays of games from response for each user
      for (let i = 0; i < responses.length; i++) {
        gameArrays.push(responses[i].response.games);
      }

      // load all App IDs of all gameArrays into its own separate array
      let allAppIds = [];
      let allGames = []; // push each game, once, into this array.
      let sharedGames = [];// array that will eventually hold all shared games
      gameArrays.forEach((gameArray, i) => {

        // new appId array for each gameArray
        let arr = [];
        // Load each game appId into new array for easier comparison
        gameArray.forEach((game, i) => {
          arr.push(game.appid);
          allGames.push(game);
        });

        allAppIds.push(arr);

      });

      let sharedAppIds = this.getCommonElementsFromArrays(allAppIds);

      // We have all shared AppIds, now match them to the game in allGames and Add
      // to a finall array of all shared games (because allGames contains name, etc..)
      sharedAppIds.forEach((appId, i) => {

        allGames.some(function (game) {
          if (game.appid === appId) {
            sharedGames.push(game);
            return true;
          }
          return false;
        });

      });

      return (
        <Grid container spacing={4}>
          {sharedGames.map((game) => (
            <Grid item key={game.appid} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={"https://media.steampowered.com/steamcommunity/public/images/apps/" + game.appid + "/" + game.img_logo_url + ".jpg"}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6" component="h6">
                    {game.name}
                  </Typography>

                  {/* <OwnedGames steamId={user.steamid} />*/}

                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" href={"https://store.steampowered.com/app/" + game.appid}>
                    View
                  </Button>
                  {/*<Button size="small" color="primary">
                    Edit
                  </Button>*/}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    }
  }
}

export default withStyles(styles)(SharedGames);
