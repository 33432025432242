import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Config from './config.js';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import { green, red } from '@material-ui/core/colors';
import ReactGA from 'react-ga'; // analytics
import {seo} from './helper/seo';

const styles = theme => ({
  inputs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
  },
  responsiveImg: {
    maxWidth: "100%"
  },
  heroButtons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  copyButton: {
    marginLeft: theme.spacing(2)
  },
  searchTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  vanityName: {
    fontWeight: 'bold'
  },
  statusIcons: {
    paddingRight: "5px"
  }
});

/**
 * This component displays the page with instructions and
 * form for retrieving a users steam ID based on their vanity URL name
 */
class VanityUrl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      vanityUrl: "",
      steamId: "",
      errorMessage: null
    };

    this.copySteamId = this.copySteamId.bind(this);
  }

  
  componentDidMount() {
    seo({
      title: 'How to find your Steam Profile ID',
      metaDescription: 'Here you can find instructions on how to find out what your Steam Id is. You can also search for a Steam ID based on a users vanity profil URL address.'
    });
  }

  fetchSteamId() {
    fetch(Config.config.backend.usersUrl + '/vanity/' + this.state.vanityUrl + '/1')
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            steamId: result.response.steamid,
            errorMessage: result.response.message ? result.response.message : null
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: "Could not load Steam ID"
          });
        }
      );
  }

  handleChange(event) {
    this.setState({
      vanityUrl: event.target.value,
      isLoaded: false,
      steamId: "",
      errorMessage: null
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (Config.config.env === 'prod') {
      ReactGA.event({
        category: 'Interaction',
        action: 'Submitted Vanity URL search'
      });
    }

    this.fetchSteamId();
  }

  copySteamId() {
    navigator.clipboard.writeText(this.state.steamId);
  }

  render() {
    const { classes } = this.props;
    const { error, vanityUrl, steamId, errorMessage } = this.state;

    let result = null;

    if (steamId !== "" && errorMessage == null) {
      result = 
      <React.Fragment>
        <span className={classes.vanityName}>{vanityUrl.charAt(0).toUpperCase() + vanityUrl.slice(1)}'s</span>&nbsp;Steam ID is: {steamId}
      </React.Fragment>;
    }

    if (error != null) {
      return <div>Error: {error}</div>;
    } else {

      return (
        <React.Fragment>
          <Container maxWidth="md">

            <Typography component="h5" variant="h5" align="left" color="textPrimary" className={classes.searchTitle}>
              How to get a users Steam ID?
        </Typography>

            <Typography component="p" variant="body1" align="left" color="textPrimary" className={classes.searchTitle}>
              A Steam ID is a unique 17 digit number that Steam uses to identify each user.
              There are several ways to retrive a users Steam ID. The easiest way is to
              visit their profile page within Steam.
        </Typography>

            <Typography component="p" variant="body1" align="left" color="textPrimary" className={classes.searchTitle}>

              <ol>
                <li>Open Steam on your Computer</li>
                <li>At the top left, click Steam -> Settings</li>
                <li>Select Interface in the left pane</li>
                <li>Make sure 'Display Steam URL address bar when available' is checked</li>
                <li>Navigate to a users steam profile</li>
              </ol>

        You should see their Profile URL near the top.
        </Typography>

            <img className={classes.responsiveImg} src="/steam-url.png" alt="steam-url" />

            <Typography component="p" variant="body1" align="left" color="textPrimary" className={classes.searchTitle}>
              In the navigation bar, the Steam ID is normally located within the URL. For example, 76561197989276951
              is the Steam ID in the above URL.
        </Typography>

            <Typography component="h5" variant="h5" align="left" color="textPrimary" className={classes.searchTitle}>
              Custom URLs with no ID
        </Typography>

            <Typography component="p" variant="body1" align="left" color="textPrimary" className={classes.searchTitle}>
              It is possible for users to configure a custom URL for their profile. In this case you can look up
              their Steam ID using the form below. Enter their custom name into the form. For example, if the Custom
              URL is https://steamcommunity.com/id/xiaver/ then you would enter xiaver in the field below.
        </Typography>

            <form onSubmit={this.handleSubmit.bind(this)} >
              <TextField
                id="standard-basic"
                label={"Custom URL Name"}
                name={"vanity-profile"}
                className={classes.inputs}
                value={vanityUrl}
                required
                onChange={this.handleChange.bind(this)} />

              <div className={classes.heroButtons}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>

                  {errorMessage != null && 
                    <ErrorIcon className={classes.statusIcons} style={{color: red[500]}} />
                  }
                  {errorMessage}


                  {result != null &&
                    <CheckCircleOutlineIcon className={classes.statusIcons} style={{color: green[500]}} />
                  }
                  {result}

                  {result != null &&
                    <Button type="button" value="copy" variant="contained" color="primary" onClick={this.copySteamId} className={ `${classes.heroButtons} ${classes.copyButton}` }>
                      Copy
                    </Button>
                  }
                </div>
              </div>

              <Button type="submit" value="Submit" variant="contained" color="secondary" className={classes.heroButtons} disabled={this.state.isLoaded}>
                Get Steam ID
              </Button>
            </form>


          </Container>
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withStyles(styles)(VanityUrl));
